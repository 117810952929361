<template>
  <div>
    <a-form :form="form" @submit="updateParent">
      <div class="row">
        <div class="col-md-4">
          <a-form-item :label="$t('liste.nomPere')">
            <a-input
              v-decorator="[
                'parents.father.firstName',
                {
                  initialValue: parents.father.firstName,
                  rules: [
                    {
                      required: true,
                    },
                  ],
                },
              ]"
              :placeholder="$t('liste.nomPere')"
            />
          </a-form-item>
        </div>
        <div class="col-md-4">
          <a-form-item :label="$t('liste.prenomPere')">
            <a-input
              v-decorator="[
                'parents.father.lastName',
                {
                  initialValue: parents.father.lastName,
                  rules: [
                    {
                      required: true,
                    },
                  ],
                },
              ]"
              :placeholder="$t('liste.prenomPere')"
            />
          </a-form-item>
        </div>
        <div class="col-md-4">
          <a-form-item :label="$t('liste.telPere')">
            <a-input
              v-decorator="[
                'parents.father.phone',
                {
                  initialValue: parents.father.phone,
                  rules: [
                    {
                      required: false,
                      len: 8,
                      message: $t('requis.telephone'),
                    },
                  ],
                },
              ]"
              :placeholder="$t('liste.telPere')"
            />
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <a-form-item :label="$t('liste.nomPere')">
            <a-input
              v-decorator="[
                'parents.mother.firstName',
                {
                  initialValue: parents.mother.firstName,
                  rules: [
                    {
                      required: true,
                    },
                  ],
                },
              ]"
              :placeholder="$t('liste.nomPere')"
            />
          </a-form-item>
        </div>
        <div class="col-md-4">
          <a-form-item :label="$t('liste.prenomPere')">
            <a-input
              v-decorator="[
                'parents.mother.lastName',
                {
                  initialValue: parents.mother.lastName,
                  rules: [
                    {
                      required: true,
                    },
                  ],
                },
              ]"
              :placeholder="$t('liste.prenomPere')"
            />
          </a-form-item>
        </div>
        <div class="col-md-4">
          <a-form-item :label="$t('liste.telMere')">
            <a-input
              v-decorator="[
                'parents.mother.phone',
                {
                  initialValue: parents.mother.phone,
                  rules: [
                    {
                      required: false,
                      len: 8,
                      message: $t('requis.telephone'),
                    },
                  ],
                },
              ]"
              :placeholder="$t('liste.telMere')"
            />
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <a-form-item :label="$t('liste.emailParents')">
            <a-input
              v-decorator="[
                'parents.email',
                {
                  initialValue: parents.email,
                  rules: [
                    {
                      type: 'email',
                      message: $t('requis.email'),
                    },
                  ],
                },
              ]"
              :placeholder="$t('liste.emailParents')"
            />
          </a-form-item>
        </div>
        <div class="col-md-4">
          <a-form-item :label="$t('liste.adresseParents')">
            <a-input
              v-decorator="[
                'parents.adress',
                {
                  initialValue: parents.adress,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :placeholder="$t('liste.adresseParents')"
            />
          </a-form-item>
        </div>
        <div class="col-md-4">
          <a-form-item :label="$t('liste.nombreEnfants')">
            <a-input
              type="number"
              v-decorator="[
                'parents.numberOfChildren',
                {
                  initialValue: parents.numberOfChildren,
                },
              ]"
              :placeholder="$t('liste.nombreEnfants')"
            />
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <a-form-item :label="$t('liste.metierPere')">
            <a-input
              v-decorator="[
                'parents.father.job',
                {
                  initialValue: parents.father.job,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :placeholder="$t('liste.metierPere')"
            />
          </a-form-item>
        </div>
        <div class="col-md-4">
          <a-form-item :label="$t('liste.metierMere')">
            <a-input
              v-decorator="[
                'parents.mother.job',
                {
                  initialValue: parents.mother.job,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :placeholder="$t('liste.metierMere')"
            />
          </a-form-item>
        </div>
        <div class="col-md-4">
          <a-form-item :label="$t('liste.situationFamiliale')">
            <a-input
              v-decorator="[
                'parents.financialStatus',
                {
                  initialValue: parents.financialStatus,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :placeholder="$t('liste.situationFamiliale')"
            />
          </a-form-item>
        </div>
      </div>
      <br />
      <div class="row justify-content-center border-with-text mx-2 mb-2">
        <span class="border-text">{{ $t("menu.liste") }} </span>
        <a-list
          :grid="{
            gutter: childreen.length > 4 ? 12 : 24,
            column: childreen.length > 4 ? 4 : childreen.length,
          }"
          item-layout="v"
          :data-source="childreen"
          :bordered="false"
        >
          <a-list-item slot="renderItem" slot-scope="item, index">
            <a-list-item-meta
              @click="() => openProfile(item.id)"
              :description="item.description"
            >
              <a slot="title" :class="index">{{ item.title }}</a>
              <a-avatar
                size="large"
                slot="avatar"
                v-if="item.photo"
                :src="settings.base_url + '/' + item.photo"
              />
              <a-avatar
                size="large"
                slot="avatar"
                style="color: #f56a00; background-color: #fde3cf"
                v-else
              >
                {{ item.title[0].toUpperCase() }}
              </a-avatar>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </div>
      <div class="row mt-1">
        <div class="col d-flex justify-content-end">
          <a-button
            key="submit"
            type="primary"
            :loading="loadingData"
            :disabled="loadingData"
            htmlType="submit"
          >
            {{ $t("action.enregistrer") }}
          </a-button>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import apiClient from "@/services/axios";
import { notification } from "ant-design-vue";
import router from "@/router";
export default {
  props: {
    parentID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loadingData: false,
      parents: {
        father: {
          firstName: "",
          lastName: "",
        },
        mother: {
          firstName: "",
          lastName: "",
        },
        students: [],
        _id: "",
        photo: "",
      },
    };
  },
  computed: {
    ...mapState(["settings", "user"]),
    childreen: function () {
      return this.parents.students.map((child) => ({
        id: child._id,
        photo: child.photo,
        title: child.firstName + " " + child.lastName,
      }));
    },
  },
  async created() {
    await this.fetchParentData();
  },
  methods: {
    openNotificationWithIcon(type, msg, desc) {
      notification[type]({
        message: msg,
        description: desc,
      });
    },
    openProfile(id) {
      router.push(`/eleve/${id}`).catch((error) => {
        console.info(error);
      });
    },
    updateParent(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          const data = values.parents;
          apiClient
            .patch(`/parents/${this.parentID}`, { data })
            .then((res) => {
              this.$message.success(this.$t("success.parentMSA"));
              this.$emit("parentUpdated", { ...data, id: this.parentID });
            })
            .catch((e) =>
              this.openNotificationWithIcon(
                "warning",
                this.$t("changementNonEffectue"),
                this.$t("champsVides")
              )
            );
        }
      });
    },
    async fetchParentData() {
      try {
        this.loadingData = true;
        const res = await apiClient.post("/parents/filter", {
          query: { _id: this.parentID },
          aggregation: [
            {
              $lookup: {
                from: "students",
                localField: "students",
                foreignField: "_id",
                as: "students",
              },
            },
            {
              $project: {
                father: {
                  firstName: 1,
                  lastName: 1,
                  phone: 1,
                  job: 1,
                },
                mother: {
                  firstName: 1,
                  lastName: 1,
                  phone: 1,
                  job: 1,
                },
                students: 1,
                adress: 1,
                numberOfChildren: 1,
                financialStatus: 1,
                _id: 1,
                photo: 1,
                email: 1,
              },
            },
          ],
        });
        this.parents = res.data[0];
      } catch {
        this.$message.error("Could not find parent");
      } finally {
        this.loadingData = false;
      }
    },
  },
};
</script>
<style scoped>
.border-with-text {
  position: relative;
  border: 2px solid #95a3b3;
  padding: 1rem;
}

.border-with-text .border-text {
  position: absolute;
  top: -12px;
  left: 20px;
  background-color: white;
  padding: 0 10px;
  font-weight: bold;
}
</style>

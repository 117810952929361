<template>
  <div class="card">
    <a-page-header
      v-if="data.typeAS == 'A'"
      style="border: 1px solid rgb(235, 237, 240)"
      class="card-header"
      :title="data.activity.designation"
    >
      <div class="row">
        <div class="col-md-4">
          <b>{{ $t("all.startDate") }}: </b
          >{{ moment(data.activity.start).format("DD/MM/YYYY") }}
        </div>
        <div class="col-md-4">
          <b>{{ $t("all.endDate") }}: </b
          >{{
            data.activity.start
              ? moment(data.activity.end).format("DD/MM/YYYY")
              : "-"
          }}
        </div>
        <div class="col-md-4">
          <a-select
            @change="selectMode"
            show-search
            style="width: 100%"
            option-filter-prop="children"
            :placeholder="$t('recette.typePaiement')"
            :filter-option="filterOption"
          >
            <a-select-option
              :value="item._id"
              :name="item.name"
              v-for="item in receiptTypes"
              :key="item._id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-4">
          <b>{{ $t("recette.fraisInscri") }}: </b
          >{{ data.activity.inscription }} DT
        </div>
        <div class="col-md-4" v-if="!data.activity.once">
          <b>{{ $t("recette.fraisMensuel") }}: </b
          >{{ data.activity.monthly + " DT" }}
        </div>
        <div class="col-md-4" v-else>
          <b>{{ $t("recette.fraisMensuel") }}: </b>{{ $t("all.non") }} ({{
            $t("etablissement.seuleFois")
          }})
        </div>
        <a-dropdown class="col-md-4">
          <a-menu
            slot="overlay"
            @click="
              (v) => {
                validPayment(v);
              }
            "
          >
            <a-menu-item key="Receipt">
              <a-icon type="user" />{{
                $t("recette.recuPaiement")
              }}</a-menu-item
            >
            <a-menu-item key="Bill">
              <a-icon type="user" />{{
                $t("recette.facturePaiement")
              }}</a-menu-item
            >
          </a-menu>
          <a-button :loading="loadingValider" :disabled="loadingValider">
            {{ $t("recette.valider") }} <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </div>
    </a-page-header>
    <a-page-header
      v-else
      style="border: 1px solid rgb(235, 237, 240)"
      class="card-header"
      :title="data.classRoom.name"
    >
      <div class="row">
        <div class="col-md-4">
          <b>{{ $t("all.startDate") }}: </b
          >{{ moment(data.classRoom.start).format("DD/MM/YYYY") }}
        </div>
        <div class="col-md-4">
          <b>{{ $t("all.endDate") }}: </b
          >{{
            data.classRoom.start
              ? moment(data.classRoom.end).format("DD/MM/YYYY")
              : "-"
          }}
        </div>
        <div class="col-md-4">
          <a-select
            @change="selectMode"
            show-search
            style="width: 100%"
            option-filter-prop="children"
            :placeholder="$t('recette.typePaiement')"
            :filter-option="filterOption"
          >
            <a-select-option
              :value="item._id"
              v-for="item in receiptTypes"
              :key="item._id"
              :name="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-4">
          <b>{{ $t("recette.fraisInscri") }}: </b
          >{{ data.classRoom.inscription }}DT
        </div>
        <div class="col-md-4">
          <b>{{ $t("recette.fraisMensuel") }}: </b
          >{{ data.classRoom.monthly + "DT" }}
        </div>
        <a-dropdown class="col-md-4">
          <a-menu
            slot="overlay"
            @click="
              (v) => {
                validPayment(v);
              }
            "
          >
            <a-menu-item key="Receipt" :disabled="loadingValider">
              <a-icon type="file-pdf" />{{
                $t("recette.recuPaiement")
              }}</a-menu-item
            >
            <a-menu-item key="Bill" :disabled="loadingValider">
              <a-icon type="file-pdf" />{{
                $t("recette.facturePaiement")
              }}</a-menu-item
            >
          </a-menu>
          <a-button :loading="loadingValider" :disabled="loadingValider">
            Valider <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </div>
    </a-page-header>

    <div
      class="row mt-2 mb-2"
      v-if="originalReduction.monthly || originalReduction.inscription"
    >
      <div class="col-md-4 center pl-4">
        <b>{{ $t("recette.reducRepet") }} :</b>
      </div>
      <div class="col-md-4 center">
        <b> {{ $t("recette.reducInscri") }}</b>
        <a-input-number
          :min="0"
          :max="100"
          :disabled="true"
          :default-value="originalReduction.inscription"
          :v-model="data.reduction.inscription"
        />
      </div>
      <div class="col-md-4 center">
        <b> {{ $t("recette.reductionMensuel") }}</b>
        <a-input-number
          :min="0"
          :max="100"
          :disabled="true"
          :default-value="originalReduction.monthly"
          :v-model="data.reduction.monthly"
        />
      </div>
    </div>
    <!--reduction en TND -->
    <div class="row mt-2 mb-2" v-if="reductionType === 'dinar'">
      <div class="col-md-4 center pl-4">
        <b>{{ $t("recette.reducFact") }} (TND)</b>
      </div>
      <div class="col-md-1 center">
        <a-input-number
          :min="0"
          :value="TndOnTransReduction"
          @change="
            (v) => {
              TndOnTransReduction = v;
              showTotal(null, true);
            }
          "
        />
      </div>
      <div class="col-md-3 mt-3">
        <ul>
          <li>
            {{ $t("recette.avantReduction") }} :
            <b>{{ Number(total.bReduction).toFixed(2) }}</b>
          </li>
          <li>
            {{ $t("recette.apresReduction") }} :
            <b>{{ Number(total.aReduction).toFixed(2) }}</b>
          </li>
          <li>
            {{ $t("recette.reduction") }} (%) :
            <b>{{ Number(onTransReduction).toFixed(2) }}</b>
          </li>
        </ul>
      </div>
      <div class="col-md-4 center">
        <a-button @click="checkEmALL">
          <a-icon type="dollar" />{{ $t("recette.paiementTotal") }}
        </a-button>
        <br />
        <a-switch
          v-if="dataAvance.length > 0"
          v-model="withAvance"
          checked-children="Payer avec avance"
          un-checked-children="Payer sans
        avance"
          class="mt-3"
        />
      </div>
    </div>
    <!--reduction % -->
    <div class="row mt-2 mb-2" v-else>
      <div class="col-md-4 center pl-4">
        <b>{{ $t("recette.reducFact") }} (%)</b>
      </div>
      <div class="col-md-1 center">
        <a-input-number
          :min="0"
          :max="100"
          :value="onTransReduction"
          @change="
            (v) => {
              onTransReduction = v;
              showTotal(null, true);
            }
          "
        />
      </div>
      <div class="col-md-3 mt-3">
        <ul>
          <li>
            {{ $t("recette.avantReduction") }} :
            <b>{{ total.bReduction.toFixed(2) }}</b>
          </li>
          <li>
            {{ $t("recette.apresReduction") }} :
            <b>{{ total.aReduction.toFixed(2) }}</b>
          </li>
          <li>
            {{ $t("recette.reduction") }} (TND) :
            <b>{{ total.reduction.toFixed(2) }}</b>
          </li>
        </ul>
      </div>
      <div class="col-md-4 center">
        <a-button @click="checkEmALL">
          <a-icon type="dollar" />{{ $t("recette.paiementTotal") }}
        </a-button>
        <br />
        <a-switch
          v-if="dataAvance.length > 0"
          v-model="withAvance"
          checked-children="Payer avec avance"
          un-checked-children="Payer sans
        avance"
          class="mt-3"
        />
      </div>
    </div>
    <!--avance start % -->

    <div class="row m-2" v-if="dataAvance.length > 0 && withAvance">
      <div class="col-md-12">
        <a-divider>Selectionnez les avances a utilisier</a-divider>
      </div>
      <div class="col-md-12">
        <div style="margin-bottom: 16px">
          <span style="margin-left: 8px"> Historique des avances </span>
        </div>
      </div>

      <div class="col-md-9 mycontent-left">
        <a-table
          :columns="columnsAvance"
          :data-source="dataAvance"
          size="middle"
        >
          <template slot="date" slot-scope="text">
            {{ moment(text).format("DD/MM/YYYY") }}
          </template>
        </a-table>
      </div>

      <div class="col-md-3">
        <div style="margin-bottom: 16px">
          <a-badge color="green" dot>
            <a-icon type="notification" />
          </a-badge>

          <span class="h5 font-weight-bold text-success pl-2">
            Solde parent : {{ dataAvance[0].parent.solde }}
          </span>
        </div>
        <div>
          <a-form-item label="Montant avance">
            <a-input-number
              style="width: 100%"
              placeholder="Montant de l'avance"
              v-model="montantAvance"
              place
              :min="0"
              :max="
                total.aReduction > dataAvance[0].parent.solde
                  ? dataAvance[0].parent.solde
                  : total.aReduction
              "
            />
          </a-form-item>
        </div>
        <div v-show="total.aReduction - montantAvance >= 0">
          <span class="bg-light text-dark lead">
            Reste a payer : {{ total.aReduction - montantAvance }}
          </span>
        </div>
      </div>
    </div>
    <!--avance end -->

    <a-form class="card-body row overflow" :form="form">
      <div class="row m-2" v-if="modeName === 'chèque'">
        <div class="col-md-12">
          <a-divider>{{ $t("recette.saisirInfoCheque") }}</a-divider>
        </div>
        <div class="col-md-3">
          <a-form-item label="Numéro:">
            <a-input v-decorator="['cheque.num']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Date d'échance:">
            <a-date-picker v-decorator="['cheque.dateEchance']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Banque:">
            <a-input v-decorator="['cheque.bank']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Titulaire:">
            <a-input v-decorator="['cheque.titulaire']" />
          </a-form-item>
        </div>
      </div>
      <!--Chèque et espéce-->
      <div class="row m-2" v-else-if="modeName === 'Chèque et espéce'">
        <div class="col-md-12">
          <a-divider
            >{{ $t("recette.saisirInfoChequeEtEspace") }} et espéce</a-divider
          >
        </div>
        <div class="col-md-3">
          <a-form-item label="Numéro:">
            <a-input v-decorator="['cheque.num']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Date d'échance:">
            <a-date-picker v-decorator="['cheque.dateEchance']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Banque:">
            <a-input v-decorator="['cheque.bank']" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label="Titulaire:">
            <a-input v-decorator="['cheque.titulaire']" />
          </a-form-item>
        </div>
        <!--les montant-->
        <div class="col-md-6">
          <a-form-item label="Montant chèque:">
            <a-input-number
              style="width: 100%"
              v-decorator="['montantCheque']"
            />
          </a-form-item>
        </div>
        <div class="col-md-6">
          <a-form-item label="Montant espèce">
            <a-input-number
              style="width: 100%"
              v-decorator="['montantEspece']"
            />
          </a-form-item>
        </div>
      </div>
      <div class="col-md-12">
        <a-divider>Selectionnez les frais a payer</a-divider>
      </div>
      <table class="table table-striped col-md-12">
        <thead>
          <th>{{ $t("recette.paiementPour") }}</th>
          <th>{{ $t("recette.restePayer") }}</th>
          <th>{{ $t("recette.payerMain") }}</th>
        </thead>
        <tbody>
          <tr v-if="!data.inscription && data.typeData.inscription > 0">
            <td>{{ $t("recette.inscription") }}</td>
            <td>
              <del v-if="onTransReduction > 0">
                {{
                  getMonthPrix(data, data.typeData.inscription, "inscription")
                }}
              </del>
              <div v-else>
                {{
                  getMonthPrix(data, data.typeData.inscription, "inscription")
                }}
              </div>
              <sup v-if="onTransReduction > 0" class="text-success"
                >-{{ onTransReduction.toFixed(2) }}%</sup
              >
              <div v-if="onTransReduction > 0">
                ={{
                  (
                    getMonthPrix(
                      data,
                      data.typeData.inscription,
                      "inscription"
                    ) *
                    ((100 - onTransReduction) / 100)
                  ).toFixed(2)
                }}
              </div>
            </td>
            <td>
              <a-form-item v-if="!data.inscription">
                <a-switch
                  @click="
                    () => {
                      showTotal(null, false);
                    }
                  "
                  v-decorator="[
                    'inscription',
                    {
                      valuePropName: 'checked',
                      initialValue: checked['inscription'],
                    },
                  ]"
                >
                  <a-icon slot="checkedChildren" type="check" />
                  <a-icon slot="unCheckedChildren" type="close" />
                </a-switch>
              </a-form-item>
            </td>
          </tr>
          <tr v-for="(month, key) in data.months" :key="key">
            <td class="pl-2">{{ getMonthName(key) }} ({{ key }})</td>
            <td>
              <div v-if="!data.months[key]">
                {{ getMonthPrix(data, data.typeData.monthly, key) }}
                <sup v-if="onTransReduction > 0" class="text-success"
                  >-{{ onTransReduction.toFixed(2) }}%</sup
                >

                <div v-if="onTransReduction > 0">
                  ={{
                    (
                      getMonthPrix(data, data.typeData.monthly, key) *
                      ((100 - onTransReduction) / 100)
                    ).toFixed(2)
                  }}
                </div>
              </div>
              <div v-else>0</div>
            </td>
            <td>
              <a-form-item v-if="!data.months[key]">
                <a-switch
                  @click="
                    () => {
                      showTotal(null, false);
                    }
                  "
                  v-decorator="[
                    'months.' + key,
                    {
                      valuePropName: 'checked',
                      initialValue: checked[key],
                    },
                  ]"
                >
                  <a-icon slot="checkedChildren" type="check" />
                  <a-icon slot="unCheckedChildren" type="close" />
                </a-switch>
              </a-form-item>
            </td>
          </tr>
        </tbody>
      </table>
    </a-form>
  </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import writtenNumber from "written-number";
import "jspdf-autotable";
import apiClient from "@/services/axios";
import { PageHeader } from "ant-design-vue";
import { mapState } from "vuex";
import JsPDF from "jspdf";

const columnsAvance = [
  {
    title: "Date d'ajout",
    dataIndex: "createdAt",
    key: "date",
    scopedSlots: {
      customRender: "date",
    },
  },
  {
    title: "Montant espéce",
    dataIndex: "montantEspece",
    key: "montantEspece",
    sorter: (a, b) => a.montantEspece - b.montantEspece,
  },
  {
    title: "Montant chèque",
    dataIndex: "montantCheque",
    key: "montantCheque",
    sorter: (a, b) => a.montantCheque - b.montantCheque,
  },
  {
    title: "Montant avance",
    dataIndex: "totalAvance",
    key: "totalAvance",
    sorter: (a, b) => a.totalAvance - b.totalAvance,
  },
];
const dataAvance = [];

const getMonthName = (monthString) => {
  const months = {};
  const monthsArr = moment.months();
  for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];

  return monthString ? months[parseInt(monthString.split("-")[1])] : null;
};
export default {
  computed: {
    ...mapState(["settings", "user"]),
  },
  created() {
    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );
    if (building) this.reductionType = building.reductionType || "pourcentage";

    if (this.data.months) {
      for (const key in this.data.months) {
        this.checked[key] = false;
      }
    }
    apiClient
      .post("/parents/filter", {
        query: { _id: this.idParent },
        aggregation: {
          $project: {
            father: 1,
            mother: 1,
          },
        },
      })
      .then((res) => (this.parents = res.data))
      .catch();
    this.originalReduction = { ...this.data.reduction };
  },
  mounted() {
    apiClient
      .get("/avance/" + this.idParent)
      .then((res) => {
        this.dataAvance = res.data.map((e) => {
          return {
            key: e._id,
            ...e,
          };
        });
      })
      .catch((e) => {
        console.error(e.stack);
        this.$message.warning(
          "Un problème du connexion, veuillez rafraîchir la page!4"
        );
      })
      .finally(() => (this.tableLoading = false));
  },
  /*
   */
  beforeDestroy() {
    this.onTransReduction = 0;
  },
  components: {
    "a-page-header": PageHeader,
  },
  props: {
    data: {
      required: true,
    },
    receiptTypes: {
      required: true,
    },
    idParent: {
      required: true,
    },
  },
  data() {
    return {
      loadingValider: false,
      form: this.$form.createForm(this),
      mode: null,
      modeName: null,
      onTransReduction: this.data.defaultReduction
        ? this.data.defaultReduction
        : 0,
      TndOnTransReduction: 0,
      reductionType: "precentage",
      result: {},
      checked: {
        inscription: false,
      },
      total: {
        bReduction: 0,
        aReduction: 0,
        reduction: 0,
      },
      //avance
      withAvance: false,
      dataAvance,
      columnsAvance,
      loading: false,
      montantAvance: null,
    };
  },
  methods: {
    moment,
    getMonthName,
    checkEmALL() {
      let months = {};
      for (const key in this.checked) {
        this.checked[key] = !this.checked[key];
        if (key == "inscription")
          this.form.setFieldsValue({ inscription: this.checked[key] });
        else {
          months[key] = this.checked[key];
        }
        this.form.setFieldsValue({ months: months });
      }
      this.showTotal(null, false);
    },
    notZeroAmount(months, inscription) {
      if (inscription) return true;
      let result = false;
      if (!inscription || inscription == undefined) result = false;
      if (!months || months == undefined) result = false;
      else
        Object.values(months).forEach((month) => {
          if (month) result = true;
        });
      return result;
    },
    showTotal(value, reductionChanged = false) {
      let checked = this.form.getFieldsValue();
      this.total.bReduction = 0;
      this.total.aReduction = 0;
      this.total.reduction = 0;
      let monthly = this.data.typeData.monthly;
      let inscription = this.data.typeData.inscription;
      //if reduction type is TND
      if (this.reductionType === "dinar") {
        if (checked.inscription) {
          this.total.bReduction += this.getMonthPrix(
            this.data,
            inscription,
            "inscription"
          );
        }
        for (const key in checked.months) {
          if (checked.months[key]) {
            this.total.bReduction += this.getMonthPrix(this.data, monthly, key);
          }
        }
        // if (!reductionChanged && value) {
        //   if (value.target.id !== "inscription") {
        //     if (value.target.checked) {
        //       this.total.bReduction += monthly;
        //     } else {
        //       this.total.bReduction -= monthly;
        //     }
        //   } else {
        //     if (value.target.checked) {
        //       this.total.bReduction += inscription;
        //     } else {
        //       this.total.bReduction -= inscription;
        //     }
        //   }
        // }

        if (!reductionChanged)
          this.TndOnTransReduction =
            (this.total.bReduction * this.data.defaultReduction) / 100;

        this.total.aReduction = this.total.bReduction;
        this.total.aReduction -=
          this.TndOnTransReduction < this.total.aReduction
            ? this.TndOnTransReduction
            : this.total.aReduction;

        this.total.reduction = this.total.bReduction - this.total.aReduction;
        if (this.TndOnTransReduction >= this.total.bReduction)
          this.onTransReduction = 100;
        else if (this.total.bReduction > 0)
          this.onTransReduction =
            100 - (100 * this.total.aReduction) / this.total.bReduction;
        else this.onTransReduction = 0;
      }
      //if reduction type is %
      else {
        if (!reductionChanged)
          this.onTransReduction = this.data.defaultReduction;
        if (checked.inscription) {
          this.total.bReduction += this.getMonthPrix(
            this.data,
            inscription,
            "inscription"
          );
          this.total.aReduction +=
            this.getMonthPrix(this.data, inscription, "inscription") *
            ((100 - this.onTransReduction) / 100);
        }
        for (const key in checked.months) {
          if (checked.months[key]) {
            this.total.bReduction += this.getMonthPrix(this.data, monthly, key);
            this.total.aReduction +=
              this.getMonthPrix(this.data, monthly, key) *
              ((100 - this.onTransReduction) / 100);
          }
        }
        // if (!reductionChanged && value) {
        //   if (value.target.id !== "inscription") {
        //     if (value.target.checked) {
        //       this.total.bReduction += monthly;
        //       this.total.aReduction +=
        //         monthly * ((100 - this.onTransReduction) / 100);
        //     } else {
        //       this.total.bReduction -= monthly;
        //       this.total.aReduction -=
        //         monthly * ((100 - this.onTransReduction) / 100);
        //     }
        //   } else {
        //     if (value.target.checked) {
        //       this.total.bReduction += inscription;
        //       this.total.aReduction +=
        //         inscription * ((100 - this.onTransReduction) / 100);
        //     } else {
        //       this.total.bReduction -= inscription;
        //       this.total.aReduction -=
        //         inscription * ((100 - this.onTransReduction) / 100);
        //     }
        //   }
        // }

        this.total.reduction = this.total.bReduction - this.total.aReduction;
      }
    },
    validPayment(type) {
      this.loadingValider = true;
      if (!this.mode) {
        this.loadingValider = false;
        this.$message.warning("Selectionner le mode de paiement");
      } else
        this.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            this.loadingValider = true;

            let data = {
              onTransReduction: this.onTransReduction,
            };
            if (!this.notZeroAmount(values.months, values.inscription)) {
              this.$message.error(
                "vous ne pouvez pas valider un paiement d'un montant égal à zéro"
              );
              this.loadingValider = false;
              return;
            }
            if (values.months) {
              data.months = {};
              Object.keys(values.months).forEach((month) => {
                if (values.months[month]) data.months[month] = true;
              });
            }
            if (values.inscription) data["onInscription"] = true;

            //if payment mode is cheque et espéce
            if (this.modeName === "Chèque et espéce") {
              if (
                this.total.aReduction.toFixed(2) !=
                values.montantCheque + values.montantEspece
              ) {
                this.$message.warning(
                  "La somme des montants chèque et espèce doit être égale à " +
                    this.total.aReduction.toFixed(2)
                );
                this.loadingValider = false;
                return;
              }
              data.montantCheque = values.montantCheque;
              data.montantEspece = values.montantEspece;
              data.cheque = values.cheque;
            } else if (
              this.modeName === "chèque" &&
              values.cheque &&
              values.cheque.num
            ) {
              data.montantCheque = this.total.aReduction.toFixed(2);
              data.cheque = values.cheque;
            } else {
              data.montantEspece = this.total.aReduction.toFixed(2);
            }

            data.montantTotal = 0;
            data.montantTotal +=
              Number(data.montantEspece || 0) + Number(data.montantCheque || 0);
            data.payment = this.data._id;
            data.receiptType = this.mode;
            data.date = Date.now();
            data.typeFR = type.key == "Receipt" ? "R" : "F";
            data.typeAS = this.data.typeAS;

            apiClient
              .put("/transaction", data)
              .then(async (res) => {
                if (this.withAvance && this.montantAvance > 0) {
                  let avanceData = {
                    receiptType: data.receiptType,
                    parent: this.idParent,
                    totalAvance: -this.montantAvance,
                    typeReglement:
                      this.data.typeAS == "A"
                        ? "Activité/" + this.data.typeData.designation
                        : "Scolarité/" + this.data.typeData.name,
                    used: true,
                  };
                  await apiClient
                    .put("/avance", avanceData)
                    .then((res) => {
                      this.$message.success(
                        "Avance débiter du solde parent avec succées"
                      );
                    })
                    .catch((e) => {
                      console.error(e);
                      this.$message.error(e.response.data.message);
                    });
                }
                this.$message.success("Paiment ajouté");
                this.generatePDF(res.data);
                this.$emit("reload");
              })
              .catch((e) => {
                console.log(e);
                this.$message.warning("Paiement non effectuer");
              })
              .finally(() => (this.loadingValider = false));
          } else {
            this.$message.warning("Paiement non effectuer");
            this.loadingValider = false;
          }
        });
    },
    async generatePDF(record) {
      const doc = new JsPDF();
      doc.setFont("Amiri");

      let montantHT = record.montantHT;
      let montantTTC = record.montantTotal;

      const dataType =
        record.typeAS == "A"
          ? record.payment.activity
          : record.payment.classroom;

      // get ecole logo
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      const titre =
        record.typeFR == "F"
          ? "Facture N. " + record.serial
          : "Reçue N. " + record.serial;
      doc.setFontSize(15);
      doc.text(80, 60, titre);

      // get the client name from the parent list
      const client = this.parents.find(
        (p) => p._id == record.payment.student.parent
      );
      const nomClient =
        client.mother.firstName +
        " " +
        client.mother.lastName +
        " ET " +
        client.father.firstName +
        " " +
        client.father.lastName;

      doc.text(20, 70, "Nom et Prénom : " + nomClient);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);

      const date = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));

      let arr = [];
      const array = [];

      let onTransReduction = record.onTransReduction
        ? record.onTransReduction / 100
        : 0;

      const typeReglement =
        record.typeAS == "A"
          ? "Activité/" + record.payment.activity.designation
          : "Scolarité/" + record.payment.classroom.name;

      const studentFullname =
        record.payment.student.lastName +
        " " +
        record.payment.student.firstName;

      let repReduction = record.payment.repReduction;
      let baseReduction = false;
      if (repReduction) {
        for (const month in record.months) {
          if (Number(repReduction[month])) {
            baseReduction = true;
            break;
          }
        }
        if (record.onInscription && repReduction.inscription)
          baseReduction = true;
      }

      if (record.onInscription) {
        arr.push(studentFullname);
        arr.push("Inscription ");
        arr.push(typeReglement);

        const inscriptionFee = this.getMonthPrix(
          record.payment,
          dataType.inscription,
          "inscription"
        );

        arr.push(
          record.typeFR == "F"
            ? inscriptionFee - inscriptionFee * record.tva
            : inscriptionFee
        );

        array.push(arr);
        arr = [];
      }
      if (record.months) {
        Object.keys(record.months).forEach((k) => {
          arr.push(studentFullname);

          let paymentDate = new Date(k);
          let monthName = paymentDate.toLocaleString("fr-FR", {
            month: "long",
          });
          arr.push(monthName);
          arr.push(typeReglement);

          const monthly = this.getMonthPrix(
            record.payment,
            dataType.monthly,
            k
          );

          if (baseReduction) {
            arr.push((repReduction[k] ? repReduction[k] : 0) + "%");
          }

          arr.push(
            record.typeFR == "F" ? monthly - monthly * record.tva : monthly
          );

          array.push(arr);
          arr = [];
        });
      }
      let rowData = [
        montantHT,
        montantTTC - montantHT - Number(record.fiscal),
        record.fiscal,
        montantTTC,
      ];

      if (onTransReduction) {
        if (record.typeFR == "R") {
          arr.push({
            colSpan: baseReduction ? 4 : 3,
            content: "Total sans réduction",
            styles: { valign: "middle", halign: "center", fontStyle: "bold" },
          });
          arr.push({
            content: (montantTTC / (1 - onTransReduction)).toFixed(2),
            styles: { valign: "middle", halign: "center", fontStyle: "bold" },
          });
          array.push(arr);
          arr = [];
        }
        arr.push({
          colSpan: baseReduction ? 4 : 3,
          content: "Réduction",
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        arr.push({
          content: (onTransReduction * 100).toFixed(2) + "%",
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        array.push(arr);
        arr = [];
      }

      let rowDataText = ["Total HTVA", "TVA", "Timbre", "Total TTC"];
      for (let i = 0; i < 4; i++) {
        if (record.typeFR == "R") {
          i = 3;
          rowDataText[i] = "Total";
        }
        arr.push({
          colSpan: baseReduction ? 4 : 3,
          content: rowDataText[i],
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        arr.push({
          content: parseFloat(rowData[i]).toFixed(2),
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: baseReduction
          ? [["Enfant", "Frais", "Désignation", "réduction de base", "Montant"]]
          : [["Enfant", "Frais", "Désignation", "Montant"]],
        body: array,
        foot: [
          [
            {
              colSpan: baseReduction ? 5 : 4,
              content:
                "Le montant total est " +
                writtenNumber(montantTTC, { lang: "fr" }) +
                " DT",
              styles: { halign: "center" },
            },
          ],
        ],
      });
      doc.save(titre + ".pdf");
    },
    selectMode(mode, e) {
      this.modeName = e.data.attrs.name;
      this.mode = mode;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getMonthPrix(payment, originalPrice, month) {
      originalPrice = parseFloat(originalPrice);
      if (payment.repReduction) {
        const reductionPercentage = parseFloat(payment.repReduction[month]);
        if (
          isNaN(reductionPercentage) ||
          reductionPercentage < 0 ||
          reductionPercentage > 100
        )
          return parseFloat(originalPrice);

        // Calculate the new price
        const reductionAmount = (originalPrice * reductionPercentage) / 100;
        const newPrice = originalPrice - reductionAmount;
        return parseFloat(newPrice);
      }
      return parseFloat(originalPrice);
    },
    //avance methodes
  },
};
</script>
<style scoped>
td {
  padding: 0%;
}
.center {
  margin: auto;
  width: 50%;
}
.mycontent-left {
  border-right: 1px dashed #333;
}
</style>
<style>
.ant-switch-inner {
  font-size: 18px !important;
}
</style>

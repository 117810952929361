<template>
  <div class="card">
    <div class="card-body">
      <div style="margin: 20px">
        <a-button
          type="primary"
          @click="generatePdf"
          :loading="printLoading"
          :disabled="printLoading"
        >
          <a-icon type="file-pdf" v-if="!printLoading" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>

        <a-range-picker
          style="margin-left: 20px"
          :placeholder="[$t('paiement.dateDebut'), $t('paiement.dateFin')]"
          format="DD/MM/YYYY"
          v-model="dateRangeData"
          :mode="mode"
          @change="
            (val) => {
              handleDateRange(val);
            }
          "
        />
      </div>
      <div>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            onSelectAll: onSelectAll,
          }"
          @change="tableChanged"
          rowKey="_id"
          :pagination="true"
          :data-source="data"
          :columns="columns"
          :loading="tableLoading"
          :scroll="{ x: 'max-content' }"
        >
          <template slot="serial" slot-scope="text, record">
            <a-tag
              color="red"
              v-if="
                record.status == 'cancelled' &&
                !(record.cancellation && record.cancellation.type == 'Avoir')
              "
              >{{ text }}</a-tag
            >
            <a-tag
              color="orange"
              v-else-if="
                record.cancellation && record.cancellation.type == 'Avoir'
              "
              >{{ text }}</a-tag
            >
            <p v-else>{{ text }}</p>
          </template>
          <template slot="paymentOnWhat" slot-scope="text, record">
            <div class="d-inline">
              <div v-if="record.fees">
                <a-tag
                  color="blue"
                  v-for="(value, key) in record.fees"
                  :key="key"
                  ><span>{{ formatFeeName(key) }}</span></a-tag
                >
              </div>
            </div>
          </template>
          <template slot="receiptType" slot-scope="text, record">
            <a-button
              icon="search"
              type="primary"
              block
              v-if="record.cheques.length > 0"
              @click="chequeDetailsModal(record)"
            >
              {{ text }}
            </a-button>
            <div v-else>{{ text }}</div>
          </template>
          <template slot="date" slot-scope="text">
            {{ moment(text).format("DD/MM/YYYY") }}
          </template>

          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('paiement.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("scolarite.recherche") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <a-tag color="#2db7f5" slot="userName" slot-scope="text">
            {{ text ? text : "--" }}
          </a-tag>
          <a-button
            slot="print"
            type="primary"
            slot-scope="text, record"
            @click="() => print(record)"
          >
            <a-icon type="file-pdf" />{{ $t("action.imprimer") }}
          </a-button>
          <a-button
            slot="annulation"
            type="danger"
            :loading="loadingAnnulation"
            :disabled="loadingAnnulation || record.status == 'cancelled'"
            slot-scope="text, record"
            @click="
              () => {
                selectedRecord = record;
                visibleCancelModal = true;
              }
            "
          >
            <a-icon type="delete" href="javascript:;" />{{
              $t("recette.annulation")
            }}
          </a-button>
        </a-table>
      </div>
    </div>
    <chequeModal
      v-if="chequeVisible"
      :chequeVisible="chequeVisible"
      :activeCheques="activeCheques"
      @close="handleClose"
    />
    <a-modal
      v-model="visibleCancelModal"
      :title="$t('paiement.annulation')"
      @ok="annulation(selectedRecord)"
    >
      <a-label>{{ $t("recette.type") }} : </a-label>
      <div class="text-center">
        <span class="ml-2 mr-2">{{ $t("paiement.avoir") }}</span>
        <a-switch
          :class="cancelType == 'Avoir' ? 'yellow-switch' : 'red-switch'"
          default-checked
          @change="
            (val) => {
              cancelType = val ? 'Annulation' : 'Avoir';
              visibleCancelModal = true;
            }
          "
        />
        <span class="ml-2 mr-2">{{ $t("paiement.annulation") }}</span>
      </div>
      <a-label>{{ $t("paiement.note") }} : </a-label>
      <a-textarea
        v-model="cancelNote"
        :placeholder="$t('paiement.note')"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */

import "jspdf-autotable";
import writtenNumber from "written-number";
import { mapState } from "vuex";
import apiClient from "@/services/axios";
import Vue from "vue";
import VueRouter from "vue-router";
import moment from "moment";
import { pdfHeader, printTransaction } from "@/utilities/pdfUtils";
import { formatFeeName } from "@/utilities/settings";
import chequeModal from "@/components/comptabilite/chequeModal";

Vue.use(VueRouter);

export default {
  props: {
    studentId: {
      required: true,
    },
  },
  components: {
    chequeModal,
  },
  computed: {
    ...mapState(["settings"]),
  },

  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Liste de règlements",
    });
    this.getTransactions();
  },

  data() {
    return {
      loadedChequesData: [],
      dateRangeData: ["", ""],
      mode: ["date", "date"],
      fields: [
        "serial",
        "nomClient",
        "typeReglement",
        "paiementSur",
        "receiptType",
        "montantHT",
        "montantTTC",
        "datePaiement",
      ],
      editingKey: "",
      rowData: [],
      tableLoading: true,
      parents: [],
      visibleParentFile: false,
      data: [],
      chequeVisible: false,
      activeCheques: {},
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      indeterminate: true,
      columns: [
        //numéro de série
        {
          title: this.$t("recette.numSerie"),
          dataIndex: "serial",
          key: "serial",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "serial",
          },
          onFilter: (value, record) =>
            record.serial.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.nom"),
          dataIndex: "nomClient",
          key: "nomClient",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nomClient",
          },
          onFilter: (value, record) =>
            record.nomClient.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.typeReglement"),
          dataIndex: "typeReglement",
          key: "typeReglement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "typeReglement",
          },
          onFilter: (value, record) =>
            record.typeReglement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.paiementQuoi"),
          dataIndex: "paymentOnWhat",
          key: "paymentOnWhat",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "paymentOnWhat",
          },
          onFilter: (value, record) => {
            let result = false;
            try {
              for (const key of Object.keys(record.fees)) {
                if (
                  formatFeeName(key).toLowerCase().includes(value.toLowerCase())
                )
                  result = true;
              }
            } catch {
              result = true;
            }
            return result;
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.modePaiement"),
          dataIndex: "receiptType",
          key: "receiptType",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "receiptType",
          },
          onFilter: (value, record) =>
            record.receiptType.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantHTVA"),
          dataIndex: "montantHT",
          key: "montantHT",
          sorter: (a, b) => a.montantHT - b.montantHT,
        },
        {
          title: this.$t("recette.montantTTC"),
          dataIndex: "montantTTC",
          key: "montantTTC",
          sorter: (a, b) => a.montantTTC - b.montantTTC,
        },
        {
          title: this.$t("recette.datePaiement"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            moment(record.date).format("DD/MM/YYYY").includes(value),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.doneBy"),
          dataIndex: "userName",
          key: "userName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "userName",
          },
          onFilter: (value, record) =>
            record.userName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.impression"),
          dataIndex: "print",
          key: "print",
          fixed: "right",
          scopedSlots: {
            customRender: "print",
          },
        },
        {
          title: this.$t("recette.annulation"),
          dataIndex: "annulation",
          key: "annulation",
          fixed: "right",
          scopedSlots: {
            customRender: "annulation",
          },
        },
      ],
      filtredTable: [],
      filtredTableCache: [],
      printLoading: false,
      selectedRowKeys: [], // Check here to configure the default column
      visibleCancelModal: false,
      cancelType: "Annulation",
      cancelNote: "",
      selectedRecord: {},
      loadingAnnulation: false,
    };
  },
  methods: {
    formatFeeName,
    async getTransactions() {
      this.tableLoading = true;

      await apiClient
        .get("/transaction/" + this.studentId)
        .then((res) => {
          this.data = res.data;
        })
        .catch();
      this.cacheData = this.data.map((item) => ({ ...item }));
      this.rowData = this.data.map((item) => ({ ...item }));
      this.tableLoading = false;
      this.filtredTable = this.data;
      this.selectedRowKeys = this.filtredTable.map((e) => e._id);
      this.filtredTableCache = this.filtredTable;
    },
    handleClose() {
      this.chequeVisible = false;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onSelectAll(selected, selectedRows, changeRows) {
      this.selectedRowKeys = selected
        ? this.filtredTable.map((e) => e._id)
        : [];
    },
    handleDateRange(value) {
      if (value.length == 2) {
        const start = moment(value[0]);
        const end = moment(value[1]);

        this.data = this.rowData.filter((elem) => {
          return moment(elem.date).isBetween(start, end, "days", "[]");
        });

        this.filtredTable = this.filtredTableCache.filter((elem) => {
          return moment(elem.date).isBetween(start, end, "days", "[]");
        });
      } else {
        this.data = this.rowData;
        this.filtredTable = this.filtredTableCache;
      }
    },
    chequeDetailsModal(record) {
      let recordData = JSON.parse(JSON.stringify(record));

      this.activeCheques = recordData.cheques;
      this.activeCheques.montantCheque = recordData.montantCheque;
      this.activeCheques.montantEspece = recordData.montantEspece;
      this.activeCheques.transactionId = recordData._id;
      this.chequeVisible = true;
    },

    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
      this.filtredTableCache = extra.currentDataSource;
    },
    moment,
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    print(record) {
      this.$gtag.event("Imp one reglement", {
        event_category: "Impression PDF",
        event_label: "ProfileEleve:listeReglement",
        value: 1,
      });

      printTransaction(record, this.settings);
    },
    generatePdf() {
      this.$gtag.event("Imp listeReglement d'élève", {
        event_category: "Impression PDF",
        event_label: "ProfileEleve:listeReglement",
        value: 1,
      });

      this.printLoading = true;

      let basicInfo = this.filtredTable[0] ? this.filtredTable[0] : "-- ET --";
      var title = "Liste des réglements";
      const doc = pdfHeader(this.settings, basicInfo.nomClient, title);
      var arr = [];
      const array = [];
      let sommeTTC = 0;

      let selectedData = this.filtredTable.filter((e) =>
        this.selectedRowKeys.includes(e._id)
      );
      for (let i = 0; i < selectedData.length; i++) {
        //arr.push(this.filtredTable[i].serial);
        arr.push(selectedData[i].serial);
        arr.push(
          selectedData[i].student.lastName +
            " " +
            selectedData[i].student.firstName
        );
        arr.push(moment(selectedData[i].date).format("DD/MM/YYYY"));

        arr.push(selectedData[i].typeReglement);
        arr.push(selectedData[i].receiptType);
        let reduction = selectedData[i].onTransReduction
          ? parseFloat(selectedData[i].onTransReduction).toFixed(2) + "%"
          : "--";
        arr.push(reduction);
        arr.push(selectedData[i].montantTTC.toFixed(2));
        sommeTTC += parseFloat(selectedData[i].montantTTC);
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        styles: { font: "Amiri" },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 15 : 38,
        head: [
          [
            //   "Num",
            "Num",
            "Nom enfant",
            "Date",
            "Type de réglement",
            "Mode de paiement",
            "Réduction",
            "Montant TTC",
          ],
        ],
        body: array,
        foot: [
          [
            {
              content: "Total",
              colSpan: 6,
              styles: {
                halign: "center",
              },
            },
            parseFloat(sommeTTC).toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Liste_Réglement.pdf");

      this.printLoading = false;
    },
    annulation(record) {
      this.loadingAnnulation = true;

      apiClient
        .post("/transaction/cancel/" + record._id, {
          clientName: record.nomClient,
          name: record.typeReglement,
          type: this.cancelType,
          note: this.cancelNote,
        })
        .then((res) => {
          this.getTransactions();
        })
        .catch((err) => {
          this.$message.error("Une erreur est survenue");
        })
        .finally(() => {
          this.loadingAnnulation = false;
          this.visibleCancelModal = false;
        });
    },
  },
};
</script>
<style scoped>
.ant-descriptions-item-label {
  font-weight: bold !important;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
.red-switch {
  background-color: #ff8181 !important;
}

.yellow-switch {
  background-color: #ffd968 !important;
}
</style>
